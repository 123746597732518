import React, { useState } from 'react';
import './About.css';

const faqData = [
  {
    question: "Something about GreenHaven",
    answer: "Something about GreenHaven"
  },
  {
    question: "Something about GreenHaven",
    answer: "Something about GreenHaven"
  },
  {
    question: "How do I ensure the quality of items being swapped?",
    answer: ""
  },
  {
    question: "How do I communicate with other users on EcoSwap?",
    answer: ""
  },
  {
    question: "Is there a fee for using EcoSwap?",
    answer: ""
  }
];

const App = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="about-section">
      <div className="faq-container">
        <h1>Frequently Asked Questions</h1>
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <div className="faq-question" onClick={() => toggleAnswer(index)}>
              {faq.question}
              <span className="toggle-icon">{openIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${openIndex === index ? 'faq-answer-enter-active' : 'faq-answer-enter'}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default App;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar'; 
import Home from './components/Home/Home';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import About from './components/About/About';
import { Element } from 'react-scroll';

function App() {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes>
                    <Route path="/" exact element={ 
                        <>
                            <Element name="home">
                                <Home />
                            </Element>
                            <Element name="about">
                                <About />
                            </Element>
                        </>
                    } />
                    
                </Routes>
            </div>
        </Router>
    );
}

export default App;

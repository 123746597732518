// Navbar.js
import React from 'react';
import './Navbar.css';
import logo from '../assets/icon.png'; // Убедись, что указал правильный путь к логотипу
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

function Navbar() {
    const navigate = useNavigate();
  
    const handleLoginClick = () => {
      navigate('/login');
    };
  
    const handleRegisterClick = () => {
      navigate('/register');
    };

    const handleHomeClick = () => {
        navigate('/');
    };

    return (
        <header>
            <nav className="navbar">
                <div className="logo">
                    <img src={logo} alt="EcoSwap Logo" />
                    <span>GreenHaven</span>
                </div>
                <ul className="nav-links">
                    <li className="nav-item">
                        <Link to="home" smooth={true} duration={800}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <a href="#"></a>
                        <ul className="dropdown"></ul>
                    </li>
                    <li className="nav-item">
                        <Link to="about" smooth={true} duration={800}>About</Link>
                    </li>
                    <li className="nav-item"><a href="#"></a></li>
                </ul>
                <div className="auth-links">
                    
                    <a href="https://t.me/green_haven_bot"  className="get-started">Get Started</a>
                </div>
            </nav>
        </header>
    );
};

export default Navbar;

import React from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-scroll';
function Home() {
  const navigate = useNavigate();



  const handleRegisterClick = () => {
    navigate('/register');
  };
 
 
  return (
 
    <section className="hero-section" id="home">
      <div className="background-elements"></div>
      <div className="hero-wrap">
      
        <h1 className="hero-heading">Discover Sustainable Practices to Forge a Greener Future with GreenHaven</h1>
        <p className="hero-text">Chat. Recycle. Learn. Sustain</p>
        <div className="button-container">
          <button onClick={() => window.location.href = 'https://t.me/green_haven_bot'} className="hero-button-border">Get Started</button>
          <button className="hero-button-border">
          <Link to="about" smooth={true} duration={800}>Learn More</Link>
          </button>
        </div>
      </div>
    </section>
   
  );  
}

export default Home;
